import ErrorCatcher from 'core-web/components/ErrorCatcher';
import LazyLoadScript from 'core-web/components/LazyLoadScript';
import RouteResolver from 'core-web/components/RouteResolver';
import SalesforceTracking from 'core-web/components/SalesforceTracking';
import Gtm from 'core-web/components/Tracking/GTM';
import { getModel, injectModels } from 'core-web/state';
import { auth, getCustomer, initFirebase } from 'core-web/util/firebase';
import getQueryParams from 'core-web/util/getQueryParams';
import setSalesforceCookie from 'core-web/util/setSalesforceCookie';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Footer from 'theme/components/Footer';
import Header from 'theme/components/Header';
import Overlay from 'theme/components/Overlay';
import ContentWrapper from 'theme/components/wrappers/ContentWrapper';
import SiteWrapper from 'theme/components/wrappers/SiteWrapper';

class Site extends Component {
    static propTypes = {
        application: PropTypes.shape({
            applicationId: PropTypes.number,
            config: PropTypes.shape({
                header: PropTypes.object,
                footer: PropTypes.object,
            }),
            getCurrentHref: PropTypes.func.isRequired,
            locale: PropTypes.string,
            resolveApp: PropTypes.func.isRequired,
        }).isRequired,
    };

    async componentDidMount() {
        const { application, location } = this.props;
        const currentHref = application.getCurrentHref();

        const discountCode = getQueryParams(location)?.discountcode;
        if (discountCode) {
            sessionStorage.setItem('discountCode', discountCode);
        }

        // Resolve the application.
        if (!application.applicationId) {
            await application.resolveApp(currentHref);
        }

        if (application.locale) {
            initFirebase(application.locale);
        } else {
            initFirebase('da_DK');
        }

        const products = getModel('products');

        products.setVat();
        if (!products.categoryTree?.length) {
            products.setCategoryTree();
        }

        auth.onAuthStateChanged(async (user) => {
            if (user) {
                const customer = getModel('customer');

                if (!customer.auth) {
                    const customerData = await getCustomer();
                    const body = await customerData.body();

                    if (body) {
                        const hashedEmail = body.data?.info?.find(({ code }) => code === 'hashed_email')?.value;
                        if (hashedEmail) {
                            setSalesforceCookie(hashedEmail);
                        }
                        customer.setCustomer(body.data);
                    } else {
                        customer.initCustomer();
                    }
                }
            }
        });

        const compareProducts = Cookies.get('compare_products');

        if (compareProducts && products.updateCompareProducts) {
            products.updateCompareProducts(JSON.parse(compareProducts));
        }
    }

    render() {
        const {
            application: {
                applicationId,
                config: { header, footer },
                locale,
            },
        } = this.props;

        return applicationId === null ? null : (
            <>
                <ErrorCatcher>
                    <Gtm />
                </ErrorCatcher>
                {locale === 'sv_SE' && (
                    <ErrorCatcher>
                        <LazyLoadScript
                            src="https://static.zdassets.com/ekr/snippet.js?key=7b6f0dba-99ad-4c57-80f9-6c210464f86d"
                            on="timeout"
                            options={{
                                id: 'ze-snippet',
                                timeout: 5000,
                            }}
                        />
                    </ErrorCatcher>
                )}
                <ErrorCatcher>
                    <Overlay />
                </ErrorCatcher>
                <SiteWrapper>
                    <ErrorCatcher>
                        <SalesforceTracking />
                    </ErrorCatcher>
                    <ErrorCatcher>
                        <Header content={header} />
                    </ErrorCatcher>
                    <ErrorCatcher>
                        <ContentWrapper>
                            <Switch>
                                {
                                    // Find a way for both of these to use RouteResolver in a single <Route />
                                    // This works for now.
                                }
                                <Route exact path="/" component={RouteResolver} />
                                <Route path="/:slug" component={RouteResolver} />
                            </Switch>
                        </ContentWrapper>
                    </ErrorCatcher>
                    <ErrorCatcher>
                        <Footer content={footer} />
                    </ErrorCatcher>
                </SiteWrapper>
            </>
        );
    }
}
export default withRouter(injectModels(['application'])(Site));

export { Site as DefaultSite };
