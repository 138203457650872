import { useEffect } from 'react';

interface Options {
    preload?: boolean;
    scrollOffset?: number;
    timeout?: number;
}

const addedScripts: { [key: string]: boolean } = {};

const createScript = (src: string, options: Options) => {
    if (addedScripts[src]) {
        return;
    }

    addedScripts[src] = true;

    // remove invalid script attributes
    const { scrollOffset, preload, timeout, ...scriptAttributes } = options;

    const script = document.createElement('script');

    Object.assign(script, { src, ...scriptAttributes });

    document.body.appendChild(script);
};

export default (src: string, on: 'load' | 'scroll' | 'timeout', options: Options = {}) => {
    const scrollFunction = () => {
        const scrollY: number = (document.documentElement || document.body.parentNode || document.body).scrollTop;

        if (scrollY >= (options.scrollOffset || 0)) {
            createScript(src, options);

            window.removeEventListener('scroll', scrollFunction);
        }
    };

    useEffect(() => {
        if (options.preload) {
            const link: HTMLLinkElement = document.createElement('link');

            link.href = src;
            link.rel = 'preload';
            link.as = 'script';

            document.head.appendChild(link);
        }

        switch (on) {
            case 'scroll':
                window.addEventListener('scroll', scrollFunction);
                return () => window.removeEventListener('scroll', scrollFunction);
            case 'load':
                createScript(src, options);
                break;
            case 'timeout': {
                const id = setTimeout(() => {
                    createScript(src, options);
                }, options.timeout);

                return () => clearTimeout(id);
            }
            default:
                break;
        }
    }, []);
};
