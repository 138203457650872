import { inServer } from 'core-web/constants';

const second = 1000;
const minute = second * 60;

const stringToHash = (s) => s.split('').reduce((a, b) => a + b.charCodeAt(0) * 31, 0);
export const cacheKey = (...value) => stringToHash(JSON.stringify(value));

export const cache = async (closure, key, ttl = minute) => {
    if (!inServer || typeof global.ssrCache === 'undefined') {
        return closure();
    }

    if (global.ssrCache.has(key)) {
        return global.ssrCache.get(key);
    }

    const response = await closure();
    // todo check response size to prevent massive requests being stored
    global.ssrCache.set(key, response, ttl);

    return response;
};
