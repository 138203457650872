import { cache, cacheKey } from 'core-server/util/ssrCache';
import ResolveApp from 'core-web/libs/GrebbCommerceAPI/Content/ResolveApp';
import {
    // Resolve.
    RESOLVE_APP,
    RESOLVE_APP_ERROR,
    RESOLVE_APP_SUCCESS, // Set current href/pathname for SSR
    SET_CURRENT_HREF,
    SET_CURRENT_PATHNAME,
    SET_ORIGIN, // Set pathname before visting store
    SET_PATH_BEFORE_STORE,
    SET_PROTOCOL,
    SET_STATE,
} from './constants';

export const resolveApp =
    (url = '', parameters = {}, origin = '') =>
    async (dispatch, getState) => {
        const { isFetching, currentHref } = getState().application;
        // Since we cannot access window.location.href for sure when SSR rendering,
        // we are doing this fix, accessing the url from the state.
        let resolvedUrl = url;

        if (url === '') {
            resolvedUrl = currentHref;
        }
        if (isFetching) {
            return;
        }

        dispatch({ type: RESOLVE_APP });

        try {
            const response = await cache(
                () => ResolveApp(resolvedUrl, parameters, origin),
                cacheKey(resolvedUrl, parameters, origin),
            );

            const cmsPaths = {};

            if (response.data.config.options.paths?.length > 0) {
                response.data.config.options.paths.forEach(({ type, url }) => {
                    if (type && url) {
                        cmsPaths[type] = url;
                    }
                });
            }

            const config = {
                ...response.data.config,
                options: {
                    ...response.data.config.options,
                    paths: cmsPaths,
                },
            };

            dispatch({
                type: RESOLVE_APP_SUCCESS,
                application: response.data.application,
                config,
                currency: response.data.currency,
                languages: response.data.languages,
                paths: response.data.paths,
                priceGroups: response.data.price_groups,
                shippingSettings: response.data.shipping_settings,
            });

            return response;
        } catch (e) {
            dispatch({ type: RESOLVE_APP_ERROR });
            throw e;
        }
    };

export const setCurrentHref = (currentHref) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_HREF,
        currentHref,
    });
};

export const setOrigin = (origin) => (dispatch) => {
    dispatch({
        type: SET_ORIGIN,
        origin,
    });
};

export const setProtocol = (protocol) => (dispatch) => {
    dispatch({
        type: SET_PROTOCOL,
        protocol,
    });
};

export const setCurrentPathname = (currentPathname) => (dispatch) => {
    dispatch({
        type: SET_CURRENT_PATHNAME,
        currentPathname,
    });
};

export const setPathBeforeStore = (pathBeforeStore) => (dispatch) => {
    dispatch({
        type: SET_PATH_BEFORE_STORE,
        pathBeforeStore,
    });
};

export const setState = (state) => (dispatch) => {
    dispatch({
        type: SET_STATE,
        state,
    });
};
